import useRooms from '@/compositions/useRooms';
import { mapValues, pickBy } from 'lodash';
import { computed, defineComponent } from 'vue';
export default defineComponent({
    name: 'TeamGraph',
    props: {
        team: {
            type: Object,
            required: true
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props) {
        const { rooms } = useRooms();
        const roomFactor = computed(() => {
            return mapValues(rooms.value, room => { var _a; return (_a = room.weight) !== null && _a !== void 0 ? _a : 1; });
        });
        const characters = computed(() => [
            ...new Set(Object.values(rooms.value).map(room => Object.keys(room.character)).flat())
        ]);
        const levels = computed(() => {
            if (props.team.games === undefined)
                return {};
            const scores = {};
            props.team.games.forEach(game => {
                var _a;
                const room = game.room;
                const level = game.level;
                if ((_a = scores[room]) !== null && _a !== void 0 ? _a : 0 < level) {
                    scores[room] = level + 1;
                }
            });
            return pickBy(scores, (_, key) => !['score'].includes(key));
        });
        const totalScorePerCharacter = computed(() => {
            var _a, _b;
            const characterScores = {};
            const characterWeight = {};
            Object.entries(levels.value).forEach(([room, level]) => {
                var _a, _b;
                const character = (_b = (_a = rooms.value[room]) === null || _a === void 0 ? void 0 : _a.character) !== null && _b !== void 0 ? _b : 'unknown';
                Object.entries(character).forEach(([char, weight]) => {
                    var _a, _b, _c;
                    characterScores[char] = ((_a = characterScores[char]) !== null && _a !== void 0 ? _a : 0) + (weight - 1) * level * ((_b = roomFactor.value[room]) !== null && _b !== void 0 ? _b : 1);
                    characterWeight[char] = ((_c = characterWeight[char]) !== null && _c !== void 0 ? _c : 0) + (weight - 1);
                    // eslint-disable-next-line max-len
                    //console.log('room: ' + room + ' character: ' + char + ' characterScore:' + characterScores[char] + ' weight:' + characterWeight[char])
                });
            });
            // Normalize scores per character
            for (const [key] of Object.entries(characterScores)) {
                characterScores[key] = ((_a = characterScores[key]) !== null && _a !== void 0 ? _a : 0) / ((_b = characterWeight[key]) !== null && _b !== void 0 ? _b : 1);
            }
            console.info(characterScores);
            return characterScores;
        });
        const series = computed(() => [{
                name: props.team.name,
                data: Object.values(totalScorePerCharacter.value)
            }]);
        // eslint-disable-next-line max-lines-per-function
        const chartOptions = computed(() => ({
            colors: ['#b51f24'],
            chart: {
                type: 'radar',
                toolbar: {
                    show: false
                },
            },
            xaxis: {
                categories: Object.keys(totalScorePerCharacter.value),
                labels: {
                    style: {
                        fontSize: '1.5em'
                    }
                }
            },
            markers: {
                colors: ['#b51f24']
            },
            fill: {
                colors: ['transparent']
            },
            stroke: {
                width: 5
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: 'grey',
                        fill: {
                            colors: ['#e6e6e6', '#dedede']
                        }
                    }
                }
            }
        }));
        return {
            rooms,
            series,
            chartOptions,
            totalScorePerCharacter,
            //characterTotalScore,
            levels,
            characters
        };
    }
});
