import { getScheduledRoomIdForTeam } from '@/types/teams';
import { computed } from 'vue';
import useClock from './useClock';
import useRooms from './useRooms';
// eslint-disable-next-line max-lines-per-function
const useMySchedule = (_location, team) => {
    const { now } = useClock();
    const { roomNameFromId } = useRooms();
    const remainingTime = computed(() => {
        if (team.value === null || team.value.start_time === null)
            return 0;
        return team.value.start_time - now.value + team.value.duration;
    });
    const currentRoomId = computed(() => {
        if (team.value === null)
            return null;
        if (team.value.schedule === null || team.value.schedule === undefined)
            return null;
        const scheduleDate = new Date(now.value * 1000);
        scheduleDate.setMinutes(scheduleDate.getMinutes() + 1); // Make sure we look into the upcomming timeslot
        return getScheduledRoomIdForTeam(team.value, scheduleDate.getTime() / 1000);
    });
    const currentRoomName = computed(() => {
        if (currentRoomId.value === null || remainingTime.value < 10 * 60) {
            return '';
        }
        else {
            return roomNameFromId(currentRoomId.value);
        }
    });
    return {
        currentRoomId,
        currentRoomName,
        remainingTime
    };
};
export default useMySchedule;
